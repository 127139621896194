// src/PGA.js
import React from "react";
import classes from "./partnerships.module.css";
import line_img from "../../Images/image 23.png";
import person from "../../Images/image 22.png";
import hand from "../../Images/image 24.png";
import member from "../../Images/image 21.png";
import image20 from "../../Images/image 20.png";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const Partnerships = () => {
  return (
    <div className={classes.container}>
      <Header />
      <div
        className={classes.divStyle}
        // style={{ backgroundImage: `url(${image20})` }}
      >
        <div className={classes.head1}>Partnerships</div>
        <br />
        <div className={classes.head2}>
          At PGA Bharat, our success is built on strong collaborations with
          leading organizations and industry experts. Our strategic partnerships
          drive innovation, enhance our golfing experiences, and expand our
          reach in the world of golf.
        </div>
      </div>
      <div className={classes.lineimg}>
        <img src={line_img} alt=" Model" className={classes.line_img} />
      </div>
      <div className={classes.sec}>
        <div className={classes.sec1}>
          <div className={classes.person}>
            <img src={person} alt="M" className={classes.person_img} />
          </div>
          <div className={classes.text}>
            <div className={classes.textcontent}>
              The PGA of America announced today a new partnership with Aivot
              Golf & Sports Management Private Limited, a new entity focused on
              land acquisition, recreational sports management, and the creation
              of hotel and residential developments across India.
            </div>
            <div className={classes.textcontent}>
              Established in 2023, our mission is to grow and elevate the game
              of golf across India. By combining the rich legacy and expertise
              of the PGA of America with AIVOT’s cutting-edge technology, PGA
              Bharat aims to create a world-class golfing ecosystem in India,
              making golf accessible, exciting, and inclusive for all.
            </div>
            <div className={classes.textcontent}>
              Under the partnership, PGA of America and Aivot will work to make
              a meaningful impact on the Indian golfing community. This
              collaborative effort will focus on developing new and existing
              golf infrastructure, providing unparalleled opportunities for
              aspiring golfers and promoting the sport’s accessibility and
              inclusivity.
            </div>
            <div className={classes.textcontent}>
              Initial developments are scheduled with multiple PGA of
              America-branded golf facilities located throughout India. These
              facilities will provide a mix of golf courses, academies and
              training centers, offering golfers the chance to enhance their
              skills and learn from top-tier PGA Professionals.
            </div>
            <div className={classes.textcontent}>
              Comprehensive programming designed by the PGA of America and Aivot
              will cater to golfers of all ages, while offering progressive
              learning programs for all skill levels. As part of the
              partnership, local Indian golf professionals working within the
              PGA of America Golf Academies network will have the opportunity to
              embark on a rewarding journey towards PGA of America Class A
              Membership, enriching their careers in the process.
            </div>
          </div>
        </div>
      </div>
      <div className={classes.midtext}>
        The partnership between the PGA of America and Aivot Golf & Sports
        Management Private Limited is poised to make a significant impact on
        golfing infrastructure and development throughout India.
        <br /> As the partnership progresses, multiple PGA of America Golf
        Academies will be established across the country, providing golfers
        unprecedented access to world-class training facilities and expert
        instruction.
      </div>

      <div className={classes.hand}>
        <img src={hand} alt="M" className={classes.handshake} />
      </div>
      <div className={classes.member}>
        <img src={member} alt="M" className={classes.memberimg} />
      </div>
      <Footer />
    </div>
  );
};

export default Partnerships;
