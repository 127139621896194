import React from "react";
import classes from "./MediaNews.module.css";
import grassGolfBall from "../../Images/grassGolfBall.png";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const MediaNews = () => {
  const links = [
    {
      url: "https://www.pgamediacenter.com/press-releases/2023-07-25-pga-of-america-partners-with-aivot-golf-sports-management-private-limited-to-expand-golf-in-india",
    },
    {
      url: "https://thegolfwire.com/pga-of-america-aivot-golf/",
    },
    {
      url: "https://agif.asia/2023/07/pga-of-americas-india-drive/",
    },
    {
      url: "https://www.hindustantimes.com/ht-newsletter/fairwaysandgreens14082023.html",
    },
  ];
  return (
    <div className={classes.conatainer}>
      <Header />
      <div
        className={classes.mediaNewsSection}
        style={{ backgroundImage: `url(${grassGolfBall})` }}
      >
        <div className={classes.posterText}>
          <div className={classes.heading}>Media & News</div>
          <div className={classes.posterDesc}>
            PGA Bharat is a leading organization dedicated to promoting the
            growth and development of golf in India. With a focus on creating
            world-class golf experiences, PGA Bharat has been instrumental in
            elevating the sport's popularity and accessibility.
          </div>
        </div>
      </div>
      <div className={classes.recentContainer}>
        <div className={classes.recentNews}>
          <div className={classes.individualLine}>
            Stay updated with the latest developments from PGA Bharat, including
            new golf course projects, tournament announcements, and exclusive
            insights from our team and partners.
          </div>
          <div className={classes.individualLine}>Recent News:</div>
          <div className={classes.individualLine3}>
            On 25th July 2023, PGA announced partnership with AIVOT Golf &
            Sports Management Private Limited to Expand Golf in India.
          </div>
          <div className={classes.individualLine3}>
            Following are press release links
          </div>
        </div>
        <ul className={classes.recentNews}>
          {links.map((link, index) => (
            <li key={index} className={classes.individualUrl}>
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.individualUrl}
              >
                {link.url}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className={classes.trainingCont}>
        <div className={classes.trainHeading}>Training & Development</div>
        <div className={classes.trainPara1}>
          PGA Bharat will provide comprehensive training programs for golfers
          and golf professionals at all levels, from beginners to elite
          professionals. By integrating the expertise of PGA professionals with
          AIVOT’s AI-driven performance analytics, we offer unparalleled
          coaching opportunities that help players reach their full potential.
        </div>
        <div className={classes.trainPara2}>
          PGA Bharat will provide comprehensive training programs for golfers
          and golf professionals at all levels, from beginners to elite
          professionals. By integrating the expertise of PGA professionals with
          AIVOT’s AI-driven performance analytics, we offer unparalleled
          coaching opportunities that help players reach their full potential.
        </div>
        <div className={classes.trainDevCont}>
          Our training services include:
          <li>
            Skill Development for All Levels: Programs designed to cater to
            golfers at every stage of their journey.
          </li>
          <li>
            Data-Driven Insights: Utilizing Artificial Intelligence to provide
            performance analysis, personalized training, and real-time feedback
          </li>
          <li>
            PGA Golf Academies: Nurturing the next generation of Indian golf
            talent through specialized training programs and facilities.
          </li>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MediaNews;
