import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Homescreen.module.css";
import Header from "../../Components/Header/Header";
import backImage from "../../Images/benny-hassum-c6UloF3fF4U-unsplash 1.png";
import logoimg from "../../Images/PGA Bharat Logo 2.png";
import biglogo from "../../Images/biglogo.png";
import fbIcon from "../../Images/Facebook.png";
import instaIcon from "../../Images/Instagram.png";
import vector61 from "../../Images/Vector 61.png";
import ellipse32 from "../../Images/Ellipse 32.png";
import ellipse321 from "../../Images/Ellipse 32 (1).png";
import ellipse33 from "../../Images/Ellipse 33.png";
import image25 from "../../Images/image 25.png";
import cardimage1 from "../../Images/matt-seymour-oy8io3jKiUQ-unsplash 1.png";
import cardimage2 from "../../Images/matt-seymour-oy8io3jKiUQ-unsplash 1 (1).png";
import cardimage3 from "../../Images/matt-seymour-oy8io3jKiUQ-unsplash 1 (2).png";
import Footer from "../../Components/Footer/Footer";

const HomeScreen = () => {
  const navigate = useNavigate();
  const scrollDivRef = useRef(null);
  const [activeDot, setActiveDot] = useState(0);

  const handleScroll = () => {
    const scrollLeft = scrollDivRef.current.scrollLeft;
    const totalScrollWidth = scrollDivRef.current.scrollWidth;
    const containerWidth = scrollDivRef.current.offsetWidth;

    const scrollPosition = scrollLeft / (totalScrollWidth - containerWidth);
    const pageIndex = scrollPosition >= 0.5 ? 1 : 0;
    setActiveDot(pageIndex);
  };

  useEffect(() => {
    const scrollDiv = scrollDivRef.current;
    scrollDiv.addEventListener("scroll", handleScroll);
    return () => {
      scrollDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (index) => {
    const scrollDiv = scrollDivRef.current;
    const sectionWidth = scrollDiv.clientWidth;
    scrollDiv.scrollTo({
      left: sectionWidth * index,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div>
        <Header />
        <img src={backImage} className={classes.backimg} alt="Background" />
        <img src={biglogo} className={classes.logoonback} alt="Logo" />

        {/* Social media icons */}
        <div className={classes.socialIcons}>
          <img src={fbIcon} alt="Facebook" className={classes.icon} />
          <img src={instaIcon} alt="Instagram" className={classes.icon} />
        </div>

        <div className={classes.text1}>Welcome to PGA Bharat</div>
        <div className={classes.text2}>Revolutionizing Golf in India</div>
        <div className={classes.text3}>
          PGA Bharat, a brand of AIVOT Golf & Sports Management Pvt Ltd, proudly
          holds the exclusive license of the PGA of America for India. We aim to
          elevate the game of golf across the nation, fostering talent and
          promoting excellence in the sport.
        </div>
        <div className={classes.textContainer}>
          <p className={classes.text}>Why choose PGA Bharat?</p>
        </div>

        {/* Image and Text Alternating Section */}
        <div className={classes.features}>
          <div className={`${classes.featureItem} ${classes.leftImage}`}>
            <img
              src={vector61}
              alt="Vector"
              className={classes.featureImage1}
            />
            <div className={classes.featureitemtext}>
              <div className={classes.headfeatureitem}>
                Global Expertise & Local Execution
              </div>
              <p className={classes.featureText}>
                PGA Bharat brings the highest standards of golf training and
                management to India. We provide an unrivaled experience for both
                professionals and enthusiasts, ensuring that every player
                reaches their full potential.
              </p>
            </div>
          </div>
          <div className={`${classes.featureItem} ${classes.rightImage}`}>
            <div className={classes.featureitemtext}>
              <div className={classes.headfeatureitem}>
                AI-Powered Innovation
              </div>
              <p className={classes.featureText}>
                AIVOT’s cutting-edge technology enhances course design, player
                training, and tournament management
              </p>
            </div>
            <img
              src={ellipse32}
              alt="Ellipse"
              className={classes.featureImage}
            />
          </div>
          <div className={`${classes.featureItem} ${classes.leftImage}`}>
            <img
              src={ellipse33}
              alt="Ellipse"
              className={classes.featureImage1}
            />
            <div className={classes.featureitemtext}>
              <div className={classes.headfeatureitem}>Sustainable Focus</div>
              <p className={classes.featureText}>
                Our commitment to eco-friendly practices ensures that India’s
                golf courses are both world-class and environmentally
                sustainable.
              </p>
            </div>
          </div>
          <div className={`${classes.featureItem} ${classes.rightImage}`}>
            <div className={classes.featureitemtext}>
              <div className={classes.headfeatureitem}>
                Expanding Market Reach
              </div>
              <p className={classes.featureText}>
                By building courses, hosting tournaments, and engaging
                communities, PGA Bharat is making golf more accessible and
                enjoyable for all Indians.
              </p>
            </div>
            <img
              src={ellipse321}
              alt="Ellipse"
              className={classes.featureImage}
            />
          </div>
        </div>
        <div className={classes.golfcoursediv}>
          <div className={classes.golfcoursetext}>
            Golf Course Design & Development
          </div>
          <div className={classes.insidegolfcoursediv}>
            <img
              src={image25}
              className={classes.golfcourseimg}
              alt="Golf Course"
            />
            <div className={classes.golfcoursetextdiv}>
              <div className={classes.minitexts}>
                PGA Bharat specializes in the design, development, and
                management of premium golf courses throughout India, bringing
                together world-class architecture and modern innovations to
                create courses that meet international standards.
              </div>
              <div className={classes.minitexts}>
                Our team of experts from the PGA of America, combined with
                AIVOT’s AI-driven insights, ensures that every course we design
                is optimized for both performance and sustainability.
              </div>
              <div className={classes.miniheads}>
                Strategic Golf Course Layouts
              </div>
              <div className={classes.minitexts}>
                Expertly designed to challenge players of all skill levels while
                maximizing the natural beauty of the surroundings.
              </div>
              <div className={classes.miniheads}>
                AI-Enhanced Landscape Design
              </div>
              <div className={classes.minitexts}>
                Using Artificial Intelligence to optimize course layouts for
                playability and environmental sustainability.
              </div>
              <div className={classes.miniheads}>Sustainable Development</div>
              <div className={classes.minitexts}>
                Focused on eco-friendly practices, water conservation, and
                minimal environmental impact.
              </div>
              <div className={classes.miniheads}>
                Landscaping & Environmental Sustainability
              </div>
              <div className={classes.minitexts}>
                Focus on sustainable, eco-friendly practices to ensure that
                courses are developed in harmony with nature.
              </div>
              <div className={classes.miniheads}>
                End-to-End Construction Management
              </div>
              <div className={classes.minitexts}>
                From concept to completion, we ensure each project is executed
                with precision, ensuring quality and timely delivery.
              </div>
            </div>
          </div>
        </div>
        <div className={classes.tournadiv}>
          <div className={classes.tournatext}>Tournaments & Events</div>
          <div className={classes.tounaminitext}>
            PGA Bharat is committed to bringing the excitement and prestige of
            PGA-sanctioned tournaments to India. Our goal is to offer a platform
            for Indian players to compete at the highest level while attracting
            international attention to Indian golf.
          </div>
          <div className={classes.xscrolldiv} ref={scrollDivRef}>
            <div className={classes.card}>
              <img src={cardimage1} alt="Card Image 1" />
              <div className={classes.heading}>PGA of America Tournaments</div>
              <div className={classes.description}>
                Bringing the best of professional golf to India through
                officially sanctioned PGA events.
              </div>
            </div>
            <div className={classes.card}>
              <img src={cardimage2} alt="Card Image 2" />
              <div className={classes.heading}>Local Golf Events</div>
              <div className={classes.description}>
                Organizing local tournaments to support and develop homegrown
                talent.
              </div>
            </div>
            <div className={classes.card}>
              <img src={cardimage3} alt="Card Image 3" />
              <div className={classes.heading}>
                Training Clinics & Coaching Workshops
              </div>
              <div className={classes.description}>
                Held alongside tournaments, these events will provide invaluable
                coaching and mentorship from PGA professionals.
              </div>
            </div>
          </div>

          {/* Dot Navigation */}
          <div className={classes.dotContainer}>
            <span
              className={`${classes.dot} ${
                activeDot === 0 ? classes.activeDot : ""
              }`}
              onClick={() => scrollToSection(0)}
            ></span>
            <span
              className={`${classes.dot} ${
                activeDot === 1 ? classes.activeDot : ""
              }`}
              onClick={() => scrollToSection(1)}
            ></span>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HomeScreen;
