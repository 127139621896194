import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Footer.module.css";
import footerlogo from "../../Images/PGA Bharat Logo 3.png";
import facebook from "../../Images/Facebook1.png";
import instagram from "../../Images/Instagram1.png";
import linkedIn from "../../Images/LinkedIn.png";
import whatsapp from "../../Images/WhatsApp.png";

const Footer = () => {
  const navigate = useNavigate();
  const [inputvalue, setinputvalue] = useState("");

  return (
    <div className={classes.footer}>
      <div className={classes.footerabout}>
        <img
          src={footerlogo}
          alt="PGA Bharat Logo"
          className={classes.footerlogo}
        />
        <div className={classes.footerabouttext}>
          PGA Bharat, a brand of AIVOT Golf & Sport Management Pvt Ltd, proudly
          holds the exclusive license of the PGA of America for India. We aim to
          elevate the game of golf across the nation, fostering talent and
          promoting excellence in the sport.
        </div>
        <div className={classes.followusetext}>Follow us</div>
        <div className={classes.socialIcons}>
          <img src={facebook} alt="Facebook" className={classes.socialIcon} />
          <img src={instagram} alt="Instagram" className={classes.socialIcon} />
          <img src={linkedIn} alt="LinkedIn" className={classes.socialIcon} />
        </div>
      </div>
      <div className={classes.footercompanydiv}>
        <div className={classes.companyhead} onClick={() => navigate("/")}>
          Company
        </div>
        <div
          className={classes.companydivitem}
          onClick={() => navigate("/ourstory")}
        >
          About
        </div>
        <div
          className={classes.companydivitem}
          onClick={() => navigate("/services")}
        >
          Services
        </div>
        <div
          className={classes.companydivitem}
          onClick={() => navigate("/medianews")}
        >
          Media & News
        </div>
        <div
          className={classes.companydivitem}
          onClick={() => navigate("/partner")}
        >
          Partnership
        </div>
      </div>
      <div className={classes.footershare}>
        <div className={classes.whatsappshare}>
          <img src={whatsapp} />
          <div className={classes.whatsapptext}>Connect on Whatsapp</div>
        </div>
        <div className={classes.newsletter}>SUBSCRIBE TO NEWSLETTER</div>
        <div className={classes.newsletterInputContainer}>
          <input
            type="email"
            placeholder="Enter your email"
            className={classes.newsletterInput}
            value={inputvalue}
            onChange={(e) => setinputvalue(e.target.value)}
          />
          <button
            className={classes.subscribeButton}
            onClick={() => setinputvalue("")}
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
