import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "./Header.module.css";
import PGALogo from "../../Images/PGA Bharat Logo 2.png";
import searchIcon from "../../Images/search.png";
import downArrow from "../../Images/chevron-down.png";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className={classes.header}>
        <div className={classes.leftheader}>
          <img src={PGALogo} className={classes.logoimg} />
          <div className={classes.searchbar}>
            <img
              src={searchIcon}
              alt="Search Icon"
              className={classes.searchIcon}
            />
            <input
              type="text"
              placeholder="Search here"
              className={classes.searchInput}
            />
            <button className={classes.exploreButton}>
              Explore
              <img
                src={downArrow}
                alt="Down Arrow"
                className={classes.downArrowIcon}
              />
            </button>
          </div>
        </div>
        <div className={classes.navitems}>
          <div
            onClick={() => navigate("/")}
            className={`${classes.navtext} ${
              location.pathname === "/" ? classes.navtext1 : ""
            }`}
          >
            HOME
          </div>
          <div
            onClick={() => navigate("/ourstory")}
            className={`${classes.navtext} ${
              location.pathname === "/ourstory" ? classes.navtext1 : ""
            }`}
          >
            ABOUT
          </div>
          <div
            onClick={() => navigate("/medianews")}
            className={`${classes.navtext} ${
              location.pathname === "/medianews" ? classes.navtext1 : ""
            }`}
          >
            MEDIA AND NEWS
          </div>
          <div
            onClick={() => navigate("/services")}
            className={`${classes.navtext} ${
              location.pathname === "/services" ? classes.navtext1 : ""
            }`}
          >
            SERVICES
          </div>
          <div
            onClick={() => navigate("/partner")}
            className={`${classes.navtext} ${
              location.pathname === "/partner" ? classes.navtext1 : ""
            }`}
          >
            PARTNERSHIP
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
